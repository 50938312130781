import React from 'react';
import Page from '../components/Page';
import { graphql } from 'gatsby';
import {
  Layout,
  SplitSection,
  Content,
  Image,
} from '../components/styles/Layout';
import WipeTransition from '../components/WipeTransition';

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "pouring_blue_ridge.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(
      relativePath: { eq: "toby-stodart-Sd7KirIeYA8-unsplash.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const PrivateLabel = ({ data }) => {
  return (
    <Layout style={{ overflow: 'hidden' }}>
      <SplitSection>
        <div className="imageSide">
          <Image
            style={{ height: `calc(100vh - 80px)` }}
            fluid={data.image1.childImageSharp.fluid}
            alt="Pouring a Glass of Whiskey"
          />
        </div>
        <div className="contentSide">
          <Content className="content">
            <div className="details">
              <hr />
              <h3 className="title">Private Label</h3>
              <p className="cocktailDirections">
                Have you ever dreamed of opening your own distillery, or
                starting your own line of artisinal spirits? Through our years
                of experience running Liberty Call and building new brands, we
                have become experts in helping others start their own distillery
                or private label in California. Please contact us if you are
                interested in our distillery consulting services. We can walk
                you through how to start a craft distillery from scratch, how to
                be TTB and ABC compliant, help you through audits, design award
                winning recipes, COLA and Formula Approval, equipment purchasing
                and more.
              </p>
            </div>
          </Content>
        </div>
      </SplitSection>
      <SplitSection>
        <div className="contentSide">
          <Content className="content">
            <div className="details">
              <hr />
              <h3 className="title">RTD Canned Cocktails</h3>
              <p className="cocktailDirections">
                RTD or "Ready to Drink" canned cocktails has become one of the
                fastest growing segments in the adult beverage industry, with a
                surge of growth beginning in 2020. Sales indicate RTD cocktails
                will continue to earn market share and grow between 20% - 30%
                annually in the years to come. We have adapted and invested in
                the necessary expertise and equipment to help our contracts
                enter the RTD canned cocktail market to grow their brand and
                expand their market reach.
              </p>
            </div>
          </Content>
        </div>
        <div className="imageSide">
          <Image
            style={{ height: `calc(100vh - 80px)` }}
            fluid={data.image2.childImageSharp.fluid}
            alt="Bird's Eye View of Lots of Cans"
          />
        </div>
      </SplitSection>
    </Layout>
  );
};

const privateLabel = props => (
  <Page
    transitionStatus={props.transitionStatus}
    transitionDirection="right"
    lightBackground
  >
    <meta name="theme-color" content="#000000" />
    <PrivateLabel data={props.data} />
    {props.transitionStatus === 'entering' && (
      <WipeTransition animation="finishRightToLeft" />
    )}
  </Page>
);

export default privateLabel;
